import React, { useEffect, useState } from "react";
import { sidepanalLinks } from "../../contentmanagement/sidepanal";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { CloseRounded, MenuRounded, EmailOutlinedIcon } from "@mui/icons-material";
import Image from "../../component/image";
import Text from "../Text";
import { useAuthentication } from "../../context/authContext";
import { clearStorage, removeItem } from "../../utils/localStorage";
import { MODULE, PINNTAG_BUSINESS_PROFILE, PINNTAG_USER } from "../../config/routes/RoleProtectedRoute";
import { getData, getDataTemp } from "../../utils/api";
import { enqueueSnackbar } from "notistack";
import { formatErrorMessage } from "../../utils/formatErrorMessage";
import pinntagLogo from "../../assets/img/pinntagLogo.png"
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import AddIcon from '@mui/icons-material/Add';
import useWindowWidth from "../../hooks/useWindowWidth";
import { Browser } from "leaflet";
import { axiosTempInstance } from "../../config/axiosInstance";


const Sidepanal = () => {
  const location = useLocation();
  const [isPanalOpen, setIsPanalOpen] = useState(false);

  const [addBussiness, setaddBussiness] = useState(false);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { user, setUser, businessUser, setBusinessUser } = useAuthentication();

  const handleLogout = async() => {
    try {
      const response = await axiosTempInstance.post("/auth/logout", {});
      enqueueSnackbar("Logged Out successfully", { variant: "success" });
      axiosTempInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response?.data?.token}`;
    } catch (err) {
      console.log(err, "...... err");
      enqueueSnackbar(
        err?.response?.data?.message
          ? formatErrorMessage(err?.response?.data?.message)
          : "Wrong credentials",
        {
          variant: "error",
        }
      );
    }
    setUser(null);
    setBusinessUser(null);
    clearStorage();
  
    const domain = window.location.hostname;
    document.cookie.split(";").forEach((cookie) => {
      const cookieName = cookie.split("=")[0].trim();
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.${domain}`;
    });
  
    navigate("/login");
  };  

  const fetchAllBusinessProfiles = async () => {
    setLoading(true);
    const res = await getDataTemp("business-profile/all");
    if (res.data) {
      setData(res.data?.businessProfiles);
    } else {
      console.log(res, "Error while fetching business profiles");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllBusinessProfiles();
  }, []);

  useEffect(() => {
    setaddBussiness(false);
  }, [location])


  const selectBusinessHandler = async (e, id) => {
    setLoading(true);
    const res = await getDataTemp(`business-profile/switch/${id}`);
    if (res.data) {
      console.log(res);
      setBusinessUser(res.data);
      localStorage.setItem(PINNTAG_BUSINESS_PROFILE, JSON.stringify(res.data));
      window.location.href = "/dashboard";
    } else {
      enqueueSnackbar(
        res.error?.message
          ? formatErrorMessage(res.error?.message)
          : "Something went wrong",
        {
          variant: "error",
        }
      );
    }
    setLoading(false);
  };

  const switchToUserProfile = async () => {
    const res = await getData("auth/switch/profile");
    if (res?.data) {
      setUser(res.data);
      setBusinessUser();
      removeItem(PINNTAG_BUSINESS_PROFILE);
      localStorage.setItem(PINNTAG_USER, JSON.stringify(res.data));
      navigate("/dashboard/business-details");
    } else {
      console.error(res.error, "error while switching profile");
    }
  };

  return (
    <>
      <div className={classNames(
          isPanalOpen ? "mob:w-full mob:h-full" : "mob:w-0 mob:px-0",
          "flex fixed z-30 mb-10 top-0 transition-[width] duration-500 ease-in-out mob:fixed mob:z-40 flex-col min-h-[100dvh] h-[100dvh] mob:left-0 mob:h-[calc(100dvh-134px)] p-0 w-[220px] bg-pureBlack overflow-hidden"
        )}>
        <div className="md:hidden block w-full ">

          <div className="w-full ms-auto p-2.5 flex justify-end">
            <CloseRounded
              onClick={() => setIsPanalOpen(false)}
              className="stroke-[3px] md:!hidden w-8 h-8 text-[#74746E]" />
          </div>

        </div>
        <div className="flex flex-col transition-all md:w-[220px] w-full bg-white p-2.5">
          <div className="flex gap-2 items-center">
            <img
              src={pinntagLogo}
              alt="mainlogo"
              className="h-[44px] w-[44px] sm:h-[44px] sm:w-[44px] rounded-lg"
            />
            <div className="flex flex-col justify-center">
              <p className="text-h2 font-ubuntu text-[#74746E] font-bold text-ellipsis line-clamp-1">Business</p>
              <p className="text-h2 font-ubuntu text-[#74746E] font-bold text-ellipsis line-clamp-1">Portal</p>
            </div>
          </div>
        </div>


        {/* <div className={`flex flex-col  transition-all md:w-[220px] w-full bg-white p-1.5
       ${addBussiness ? `add-buss-menu  gap-4 ${(data && data.length >= 5) && "overflow-y-scroll"}` : null}`}>
          {
            businessUser !== undefined ?
              <div className="flex gap-1.5 nothere">
                <Image
                  src={businessUser?.businessProfile.profilePhoto}
                  alt="mainlogo"
                  className="h-[44px] w-[44px] sm:h-[44px] sm:w-[44px]"
                />
                <div className="flex flex-col justify-center pe-1 ">
                  <Text className="text-caption font-ubuntu text-[#74746E]">Switch Profile</Text>
                  <Text className={`text-h2 font-ubuntu font-medium text-ellipsis overflow-hidden md:w-[100px] ${!addBussiness ? 'line-clamp-1' : null} `}>{businessUser?.businessProfile.name}</Text>
                </div>
                <div onClick={(e) => setaddBussiness(!addBussiness)} className="business-drop">
                  <ArrowDropDownRoundedIcon
                    className="text-[#6C7278] w-6 h-6 cursor-pointer"
                  />
                </div>
              </div>
              :
              (data && data.length > 0) ?
                <div className="flex gap-1.5 here">
                  <Image
                    src={user?.user?.profilePhoto}
                    alt="mainlogo"
                    className="h-[44px] w-[44px] sm:h-[44px] sm:w-[44px]"
                  />
                  <div className="hidden sm:flex flex-col justify-center pe-2 border-r border-[#DCE4E8]">
                    <Text className="text-caption font-ubuntu text-[#74746E]">Switch Profile</Text>
                    <Text className={`text-h2 font-ubuntu font-medium text-ellipsis overflow-hidden md:w-[100px] ${!addBussiness ? 'line-clamp-1' : null}`}>{user?.user?.firstName}</Text>
                  </div>
                  <div onClick={(e) => setaddBussiness(!addBussiness)} className="business-drop">
                    <ArrowDropDownRoundedIcon
                      className="text-[#6C7278] w-6 h-6 cursor-pointer"
                    />
                  </div>
                </div> :

                <div className="flex item-center gap-3 ms-0">
                  <div className="icon-part w-[44px] h-[44px] bg-[#F5F5F3] flex items-center justify-center rounded-md">
                    <AddIcon className="w-[24] h-[24px] text-[#74746E]" />
                  </div>
                  <Link to="/dashboard/business-details">
                    <div className="flex items-center">
                      <Text className="text-h2 font-ubuntu font-medium text-[#74746E]">Add Business</Text>
                    </div>
                  </Link>
                </div>
          }


          {
            addBussiness ?
              <>

                {user && businessUser !== undefined && (
                  <div>
                    <p onClick={switchToUserProfile} className="flex item-center gap-3 ms-0 mb-2.5 cursor-pointer">
                      <div className="icon-part w-[44px] h-[44px] flex items-center justify-center rounded-md">
                        <Image
                          src={user?.user?.profilePhoto}
                          alt="profile icon"
                          className="h-[44px] w-[44px] sm:h-[44px] sm:w-[44px]"
                        />
                      </div>
                      <div className="flex items-center">
                        <Text className="text-h2 font-ubuntu font-medium text-[#74746E]  text-ellipsis overflow-hidden md:w-[100px]">{user?.user?.firstName}</Text>
                      </div>
                    </p>
                  </div>
                )
                }
                {
                  data && data.map((item, index) => {
                    if (businessUser && item._id === businessUser?.businessProfile._id) {
                      return null;
                    }

                    return (
                      <div key={index} className="flex item-center gap-3 ms-0 mb-2.5 cursor-pointer"
                        onClick={(event) =>
                          selectBusinessHandler(event, item._id)
                        }>
                        <div className="icon-part w-[44px] h-[44px] flex items-center justify-center rounded-md">
                          <Image
                            src={item.profilePhoto}
                            alt="mainlogo"
                            className="h-[44px] w-[44px] sm:h-[44px] sm:w-[44px]"
                          />
                        </div>
                        <div className="flex items-center">
                          <Text className="text-h2 font-ubuntu font-medium text-[#74746E]  text-ellipsis overflow-hidden md:w-[100px]">{item.name}</Text>
                        </div>
                      </div>
                    );
                  })
                }


                <div className="flex item-center gap-3 ms-0 mb-2.5">
                  <div className="icon-part w-[44px] h-[44px] bg-[#F5F5F3] flex items-center justify-center rounded-md">
                    <AddIcon className="w-[24] h-[24px] text-[#74746E]" />
                  </div>
                  <Link to="/dashboard/business-details">
                    <div className="flex items-center">
                      <Text className="text-h2 font-ubuntu font-medium text-[#74746E]">Add Business</Text>
                    </div>
                  </Link>
                </div>

              </>
              : null
          }

        </div> */}

        <div className={useWindowWidth() >= 767 ? "sidebar-navs pt-3" : "sidebar-navs"}>
          {user && (
            <>
              {sidepanalLinks.map((items, index) => {
                if (businessUser && items.module === MODULE.BUSINESS) {
                  return (
                    <React.Fragment key={index}>
                      <Link onClick={() => setIsPanalOpen(false)} to={items.Link} target={items.target ? "_blank" : "_self"}>
                        <div
                          className={classNames(
                            isPanalOpen ? "mob:block" : "mob:hidden",
                            location.pathname === items.Link
                              ? "bg-ButtonPrimary   text-white font-bold  "
                              : "hover:bg-tertiaryDark ",
                            "w-full transition-[display]  duration-500 ease-in-out  cursor-pointer  active:shadow-none p-4  text-[#74746E]"
                          )}
                        >
                          <div className="flex  w-full gap-2">
                            <img src={(location.pathname === items.Link) ? items.acticon : items.icons} alt="" className=" hover:text-white w-[20px] h-[20px]" />
                            <p className=" whitespace-nowrap text-sm">{items.Title}</p>
                          </div>

                        </div>
                      </Link>
                    </React.Fragment>
                  );
                } else if (items.module === MODULE.USER && !businessUser) {
                  return (
                    <React.Fragment key={index}>
                      <Link onClick={() => setIsPanalOpen(false)} to={items.Link} target={items.target ? "_blank" : "_self"} >
                        <div
                          className={classNames(
                            isPanalOpen ? "mob:block" : "mob:hidden",
                            location.pathname === items.Link
                              ? "bg-ButtonPrimary   text-white font-bold  "
                              : "hover:bg-tertiaryDark ",
                            "w-full transition-[display]  duration-500 ease-in-out  cursor-pointer  active:shadow-none p-4  text-[#74746E]"
                          )}
                        >
                          <div className="flex  w-full gap-2">
                            <img src={(location.pathname === items.Link) ? items.acticon : items.icons} alt="" className=" hover:text-white w-[20px] h-[20px]" />
                            <p className=" whitespace-nowrap text-sm">{items.Title}</p>
                          </div>

                        </div>
                      </Link>
                    </React.Fragment>
                  );
                } else {
                  return <React.Fragment key={index}></React.Fragment>
                }
              })}

              <div
                className={classNames(
                  isPanalOpen ? "mob:block" : "mob:hidden",
                  "absolute bottom-0 w-full transition-[display] duration-500 ease-in-out cursor-pointer  py-2 text-center text-base bg-ButtonPrimary text-white font-bold"
                )}
                onClick={handleLogout}
              >
                Logout
              </div>
            </>
          )}
        </div>
      </div>
      <div
        onClick={() => setIsPanalOpen(true)}
        className={classNames(
          isPanalOpen && "!hidden",
          "hidden  mob:flex justify-center items-center z-0 top-5 left-4 mob:fixed mob:z-40 w-[30px] h-[30px]"
        )}
      >
        <MenuRounded className="text-black w-[20px] h-[20px]" />
      </div>
    </>
  );
};

export default Sidepanal;
