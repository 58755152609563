import React, { useEffect, useState } from 'react'
import Header from "../Header";
import Sidepanal from "../Sidepanal";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";

import UserIcon from '../../assets/icons/User-2.svg';
import BusinessIcon from '../../assets/icons/Business.svg';
import CoinIcon from '../../assets/icons/Coin.svg';
import PaymentIcon from '../../assets/icons/Payment.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import cn from "classnames";
import useWindowWidth from '../../hooks/useWindowWidth';

const dashMenu = [
    { title: 'User Details', icon: UserIcon, link: '/dashboard/edit-user' },
    { title: 'My Businesses', icon: BusinessIcon, link: '/dashboard/business-details' },
    { title: 'Payment History', icon: CoinIcon, link: '/dashboard/payment-history' },
    { title: 'Payment Methods', icon: PaymentIcon, link: '/dashboard/payment-method' },
]


export default function DashboardLayout() {

    let location = useLocation();
    const [openMenu, setopenMenu] = useState(false);

    useEffect(() => {
        if(openMenu) setopenMenu(false); 
    }, [location.pathname]);

    const handleMenuClick = () => {
        setopenMenu(!openMenu); 
    };


    return (
        <div className="h-min-screen">
            <Header />

            <Sidepanal />
            <div className="layout-child">

                <div className="md:mx-10 mx-5 overflow-hidden">

                    <div className={cn(useWindowWidth() > 767 && "flex", "md:flex gap-5 md:p-5 mb-4 md:border border-[#E3E3D8] rounded-2xl md:flex-nowrap flex-wrap")}>

                        {/* <div className="md:w-[220px] w-full h-full ">
                            <div className="md:flex hidden md:flex-col md:gap-3 busi-detail-menu">
                                {
                                    dashMenu.map((item, index) => (
                                        <NavLink activeClassName='is-dashlink-active' to={item.link} key={index} className="py-3 px-5 flex items-center gap-3 font-ubuntu cursor-pointer rounded-lg hover:bg-[#E3E3D8]">
                                            <img src={item.icon} alt={item.title} className="w-[18px] h-[18px]" />
                                            <span className="text-[#61615A] text-sm">{item.title}</span>
                                        </NavLink>
                                    ))
                                }
                            </div>
                            <div className="md:hidden flex md:flex-col md:gap-3 busi-detail-menu">
                                {
                                    dashMenu.map((item, index) => {
                                        if (item.link === location.pathname) {
                                            return (
                                                <Link onClick={handleMenuClick} to={item.link} key={index} className="py-3 px-5 flex items-center gap-3 font-ubuntu cursor-pointer justify-between rounded-lg w-full relative border border-[#DCE4E8]">
                                                    <span className='flex items-center gap-1'>
                                                        <img src={item.icon} alt={item.title} className="w-[18px] h-[18px]" />
                                                        <span className="text-[#61615A] text-sm">{item.title}</span>
                                                    </span>
                                                    <KeyboardArrowDownIcon className='w-6 h-6' />
                                                </Link >
                                            )
                                        }
                                    })
                                }
                                {openMenu ?
                                    dashMenu.map((item, index) => {
                                        if (item.link !== location.pathname) {
                                            return (
                                                <Link to={item.link} key={index} className="py-3 px-5 flex items-center gap-3 font-ubuntu cursor-pointer rounded-lg hover:bg-[#E3E3D8]">
                                                    <img src={item.icon} alt={item.title} className="w-[18px] h-[18px]" />
                                                    <span className="text-[#61615A] text-sm">{item.title}</span>
                                                </Link>
                                            )
                                        }
                                    }) : null
                                }
                            </div>
                        </div> */}

                        <div className="md:pl-5 border-[#DCE4E8]">
                            <Outlet />
                        </div>

                    </div>
                </div>

            </div>

        </div>
    )
}
