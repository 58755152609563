import React from "react";

const CircularImage = ({src, alt, className}) => {
  return (
    <img
      src={src}
      alt={alt}
      className={className ? className : "rounded-full w-full h-full object-cover"}
    />
  );
};

export default CircularImage;
