import React from "react";
import classNames from "classnames";
import { CircularProgress } from "@mui/material";

const PrimaryButton = ({
  children,
  onClick = () => {},
  inputClass,
  loading,
  isSocialAuth,
  isDisabled = false,
}) => {
  return (
    <div
      onClick={(e) => {
        if (!isDisabled) {
          onClick(e);
        }
      }}
      dis
      className={classNames([
        inputClass,
        " w-full h-[48px] bg-[#03B4BF] cursor-pointer text-white  px-3 py-0.5 rounded-full flex justify-center items-center gap-1",
        {
          "bg-opacity-65 pointer-events-none": loading,
        },
        {
          "cursor-not-allowed pointer-events-none bg-gray-300 text-gray-400":
            isDisabled,
        },
      ])}
    >
      {loading ? (
        <CircularProgress className="!w-5 !h-5 !text-white" />
      ) : (
        children
      )}
    </div>
  );
};

export default PrimaryButton;
