import moment from "moment/moment";
import DataTable, { createTheme } from "react-data-table-component";

// const PaymentHistData = [
//   {
//     date:'25 Dec 2023',
//     business_name:'The Buff Restaurant',
//     location:1,
//     plan: 'yearly',
//     amount : '$360,00',
//     paymentmethod : 'Apple subscription',
//     periodstart:'25 Dec 2023',
//     periodend:'25 Dec 2023',
//     days:'30'
//   },
//   {
//     date:'25 Dec 2023',
//     business_name:'The Buff Restaurant',
//     location:1,
//     plan: 'yearly',
//     amount : '$360,00',
//     paymentmethod : 'Apple subscription',
//     periodstart:'25 Dec 2023',
//     periodend:'25 Dec 2023',
//     days:'30'
//   },
//   {
//     date:'25 Dec 2023',
//     business_name:'The Buff Restaurant',
//     location:1,
//     plan: 'yearly',
//     amount : '$360,00',
//     paymentmethod : 'Apple subscription',
//     periodstart:'25 Dec 2023',
//     periodend:'25 Dec 2023',
//     days:'30'
//   }
// ]

const PaymentHistoryTable = ({ data }) => {
  const columns = [
    {
      name: "Date",
      selector: (row) =>
        row.createdAt ? moment(row.createdAt).format("DD-MMM-YY") : "",
      left: true,
      minWidth: "150px"
    },
    {
      name: "Plan",
      selector: (row) => (
        <span className="capitalize">
          {row.subscription?.product?.durationType || ""}
        </span>
      ),
      left: true,
      minWidth: "90px",
    },
    {
      name: "Payment Method",
      selector: (row) => "Card",
      left: true,
      minWidth: "150px",
    },
    {
      name: "Period Start",
      selector: (row) =>
        row.description === "Subscription trial period"
          ? row.subscription?.startDate
            ? moment(row.subscription.startDate).format("DD-MMM-YY")
            : ""
          : row.subscription?.invoiceStartDate
          ? moment(row.subscription.invoiceStartDate).format("DD-MMM-YY")
          : "",
          left: true,
          minWidth: "150px",
    },
    {
      name: "Period End",
      selector: (row) =>
        row.description === "Subscription trial period"
          ? row.subscription?.endDate
            ? moment(row.subscription.endDate).format("DD-MMM-YY")
            : ""
          : row.subscription?.invoiceEndDate
          ? moment(row.subscription.invoiceEndDate).format("DD-MMM-YY")
          : "",
          left: true,
          minWidth: "150px",
    },
    {
      name: "Days",
      selector: (row) =>
        // row.description === "Subscription trial period"
        //   ? row.subscription?.endDate && row.updatedAt
        //     ? moment(row.subscription.endDate).diff(
        //         moment(row.updatedAt),
        //         "days"
        //       )
        //     : ""
        //   : row.subscription?.invoiceEndDate && row.updatedAt
        //   ? moment(row.subscription.invoiceEndDate).diff(
        //       moment(row.updatedAt),
        //       "days"
        //     )
        //   : "",
        row.description === "Subscription trial period"
          ? 0
          : moment(row.subscription.endDate).diff(
              moment(row.updatedAt),
              "days"
            ),
            left: true,
            minWidth: "70px",
    },
    {
      name: "Locations",
      selector: (row) => row.quantity || "",
      left: true,
      minWidth: "100px",
    },
    {
      name: "Amount",
      selector: (row) => "$ " + (row.amount || "0"),
      right: true,
      minWidth: "90px",
    },
    {
      name: "Status",
      selector: (row) => {
        if (row.description === "Subscription trial period") {
          return "Trial";
        } else if (row.status === 1) {
          return "Paid";
        } else {
          return "Not Paid";
        }
      },
      left: true,
      minWidth: "90px",
    },
  ];

  // const calculateColumnWidth = (headerText, avgCharWidth = 10, padding = 30, extraSpace = 50) => {
  //   const minWidth = headerText.length * avgCharWidth + padding;
  //   const maxWidth = minWidth + extraSpace;
  //   return { minWidth: `${minWidth}px`, maxWidth: `${maxWidth}px` };
  // };

  // const newColumns = columns.map((column) => (
  //   {
  //     ...column,
  //     ...calculateColumnWidth(column.name),
  //   }
  // ))

  createTheme(
    "solarized",
    {
      text: {
        primary: "#000000",
        secondary: "#ffffff",
      },
      background: {
        default: "rgba(0,0,0,0)",
        text: "#FFFFFF",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#fff",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,1)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "light"
  );

  const customStyles = {
    rows: {
      style: {
        fontSize: "14px",
        borderColor: "#ffffff",
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        color: "#ACB5BB",
        borderColor: "#ffffff",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
        padding: "10px 16px",
      },
    },
  };

  return (
    <div>
      <DataTable
        theme="solarized"
        columns={columns}
        data={data}
        customStyles={customStyles}
        pagination
      />
    </div>
  );
};

export default PaymentHistoryTable;
