import { Modal } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import classNames from "classnames";
import React from "react";

const PrimaryModal = ({ open, handleClose, children, modalClass, title, fixed }) => {

  const handleModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className={classNames([
          "md:w-1/2 w-full min-h-[25vh] max-h-[90vh] overflow-x-hidden pl-5 outline-none bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 border-none rounded-lg",
          modalClass,
        ])}
      >
        <div className="py-2 pr-5 pb-0 overflow-auto h-full image-vertical-scroll">
          {title && (
            <div className={fixed ? "bg-white sticky top-0 justify-between flex w-full" : "pt-2 pb-4 flex justify-between"}>
              <h1 className="text-center font-medium text-xl">{title}</h1>
              <CloseIcon onClick={() => handleClose()} className="w-6 h-6 text-[#9A9A9A] cursor-pointer" />
            </div>
          )}
          {children}
        </div>
      </div>
    </Modal>
  );
};

export default PrimaryModal;
